/* Style the header */
#homepage-logo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 10vh;  */
    min-height: auto;
    top: 0;
    left: 0;
    /* padding-top: 10vh;  */
}

/* Style the logo image */
.logo-image {
    max-width: 100%; /* Make sure the logo doesn't exceed the header width */
    max-height: 80%; /* Adjust the height as needed */
    width: 150px;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 768px) {
    #homepage-logo-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 10vh; 
        top: 0;
        left: 0;
    }

    .logo-image {
        width: 80px;
    }
}

.buttons {
    display: flex;
    flex-direction: column; 
    justify-content: center;   
}

.contribute-button {
    background-color: #4CAF50; /* blue */
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 200px;
    margin-right: 30px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
.contribute-button:hover {
background-color: #0056b3; /* darker blue */
color: white;
}

.contribute-button:disabled {
background-color: #ccc; /* gray */
cursor: not-allowed;
}
  

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Aligns children to the bottom */
  }

  .logo-text {
    font-size: 1.2rem;
    color: #333;
    max-width: 100%; 
    margin: 0 auto;
    line-height: 1.5;
    font-family: 'DM Sans', sans-serif;
}

