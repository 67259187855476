/* Modal Container */
#contributeModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
  
/* Modal Content */
#contributeModalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  
  /* Close Button */
  #contributeModalContent .close {
    position: absolute;
    background-color: gray;
    top: 10px;  /* Adjust as needed */
    right: 10px;  /* Adjust as needed */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Label Styling */
  #contributeModal label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Input and Select Box */
  #contributeModal input[type="text"],
  #contributeModal select {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Submit Button */
  #contributeModal .submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  
  /* Show/Hide modal */
  #contributeModal {
    display: none;  /* hidden by default */
  }
  
  #contributeModal.is-open {
    display: flex;  /* shown when isOpen is true */
  }


  #contributeModal input[type="text"]:focus {
    outline: none;
    background-color: #e5e5e5;
  }
  
  #contributeModal .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  #contributeModal .option-button {
    background-color: #e9e9e9;
    color: #444444;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  #contributeModal .option-button:hover {
    background-color: #444444;
    color: #ffffff;
  }
  
  #contributeModal .submit-button {
    background-color: #2ecc71;
    color: white;
    padding: 14px 30px;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    #contributeModalContent{
      width: 90%;
    }
  }